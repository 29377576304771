import Image from "next/image";

type Props = {
  url: string | undefined;
  name: string | undefined;
  subText: string;
  isVerified: boolean;
};

export default function UserCassette({
  url,
  name,
  subText,
  isVerified,
}: Props) {
  return (
    <div className="flex items-center">
      <div className="h-10 w-10 rounded-full overflow-hidden mr-2 sp:h-6 sp:w-6">
        <Image
          src={url || "/images/no-image-user.png"}
          width={40}
          height={40}
          alt={name || "-"}
          title={name || "-"}
        />
      </div>
      <div className="sp:flex sp:flex-col">
        <div className="flex items-center">
          <p className="font-bold leading-none sp:text-sm sp:leading-none sp:mb-1">{name}</p>
          {isVerified && (
            <Image
              src="/images/verified.svg"
              width={16}
              height={16}
              alt="verified"
              title="verified"
              unoptimized
              className="ml-1"
            />
          )}
        </div>
        <time className="font-bold text-xs text-neutral leading-none">{subText}</time>
      </div>
    </div>
  );
}
